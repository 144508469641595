//#region Backend class equivalent to json object
const customerPhone = {
    telephoneId: 0,
    customerId: 0,
    telephoneType: 1,
    telephonePrefix: null,
    telephoneNumber: null,
    telephoneIsPrimary: true,
};
const customerAddress = {
    addressId: 0,
    customerId: 0,
    addressName: null,
    addressCompany: null,
    addressLine1: null,
    addressLine2: null,
    addressCity: null,
    addressState: null,
    addressPostalCode: null,
    addressIsPrimary: true,
    addressCountryCode: '',
    addressCreated: "1/1/0001"
};

const customField = {
    fieldId: 0,
    fieldName: '',
    fieldValue: ''
}


//#endregion

//#region DateTime Formatter
const dateFormatter = {   
    date :(date,dateFormat) => {
        let createdDate = "";
        let currentMonth = new Date(date).getMonth() + 1;
        let month = currentMonth > 9 ? `${currentMonth}` : `0${currentMonth}`;
        let currentDay = new Date(date).getDate();
        let day = currentDay > 9 ? `${currentDay}` : `0${currentDay}`;
        if(dateFormat === 'dd/mm/yyyy'){
            createdDate = `${day}/${month}/${new Date(date).getFullYear()}`;
        }
        if(dateFormat === 'mm/dd/yyyy'){
            createdDate = `${month}/${day}/${new Date(date).getFullYear()}`;
        }
        return createdDate;
    },
    flatDate :(dateFormat) => {
        let format = '';
        if(dateFormat === 'dd/mm/yyyy'){
            format = 'd/m/Y';
        }
        if(dateFormat === 'mm/dd/yyyy'){
            format = 'm/d/Y';
        }
        return format;
    },
  
}
//#endregion

//#region  model to be used in form
const customerModel = {
    customerId: 0,
    applicationId: 0,
    customerKey: null,
    email : "",
    title : 1,
    firstName: "",
    lastName: "",
    isEmailConfirmed: 0,
    internalRef:"",
    languageCode : "",
    currencyCode: "",
    isActive: false,
    acceptMarketing: false,
    membershipId: null,
    membershipSubscriptionId: "",
    membershipExpiry: null,
    createdDate : "1/1/0001",
    updatedDate:"1/1/0001",
    lastLoginDate: "1/1/0001",
    customerPhones: [{ ...customerPhone }],
    customerAddresses : [{ ...customerAddress }],
    customFields: [{...customField}]
};
//#endregion

//#region dummy data
const customerLists= [
    {"customerId":1,"applicationId":"100","email":"customer1@gmail.com","title":"test1","firstName": "customer1","lastName": "cust","fullName":"customer1 cust1","languageCode" : "EN","currencyCode": "USD","createdDate": '2021-05-14',"isActive":true}, 
    {"customerId":2,"applicationId":"100","email":"customer2@gmail.com","title":"test2","firstName": "customer2","lastName": "cust","fullName":"customer2 cust2","languageCode" : "EN","currencyCode": "USD","createdDate": '2021-05-14',"isActive":false},  
    {"customerId":3,"applicationId":"100","email":"customer3@gmail.com","title":"test3","firstName": "customer3","lastName": "cust","fullName":"customer3 cust3","languageCode" : "EN","currencyCode": "USD","createdDate": '2021-05-14',"isActive":true},  
    {"customerId":4,"applicationId":"100","email":"customer4@gmail.com","title":"test4","firstName": "customer4","lastName": "cust","fullName":"customer4 cust4","languageCode" : "EN","currencyCode": "USD","createdDate": '2021-05-14',"isActive":true},  
    {"customerId":5,"applicationId":"100","email":"customer5@gmail.com","title":"test5","firstName": "customer5","lastName": "cust","fullName":"customer5 cust5","languageCode" : "EN","currencyCode": "USD","createdDate": '2021-05-14',"isActive":false},  
    {"customerId":6,"applicationId":"100","email":"customer6@gmail.com","title":"test6","firstName": "customer6","lastName": "cust","fullName":"customer6 cust6","languageCode" : "EN","currencyCode": "USD","createdDate": '2021-05-14',"isActive":true},     
];

//#endregion  


const customerHelper = {
    customerModel: customerModel,    
    customerLists: customerLists,
    customerAddress : customerAddress,
    customerPhone : customerPhone,
    dateFormatter : dateFormatter
    
}

export default customerHelper;