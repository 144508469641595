import axios from "@/axios.js"
import _ from 'lodash'
import helper from "./customerHelper"

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    customers: [],
    formData: {},
    deleteDialog: { show: true, priceRuleId: 0, affectedUsers: 0 }
  },
  mutations: {
    SET_CUSTOMER_LIST(state, customers) {
      state.customers = customers;
    },
    SET_FORM_DATA(state, formData) {
      state.formData = formData;
    },
    ADD_CUSTOMER(state, customer1) {
      state.customers.push(customer1)
    },
    UPDATE_CUSTOMER(state, customer1) {
      const customerIndex = _.findIndex(state.customers, (item) => item.customerId === customer1.customerId)
      state.customers[customerIndex] = customer1
    },
    REMOVE_CUSTOMER(state, customerId) {
      const customerIndex = _.findIndex(state.customers, (item) => item.customerId === customerId)
      state.customers.splice(customerIndex, 1)
    },
    SET_DELETE_DIALOG(state, deleteDialog) {
      state.deleteDialog = deleteDialog
    }
  },

  actions: {
    fetchFormData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = `api/customersetting/formdata/${payload}`;
        axios.get(url)
          .then(response => {
            commit('SET_FORM_DATA', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    fetchCustomers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = `api/customersetting/${payload}/customer`//`api/companies/${payload}/roles`
        axios.get(url)
          .then(response => {
            commit('SET_CUSTOMER_LIST', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    addCustomer({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = `api/customersetting/${payload.applicationId}/customer`;
        axios.post(url, payload.data) //{ model: JSON.stringify(payload.data) }
          .then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    updateCustomer({ commit }, payload) {
      return new Promise((resolve, reject) => {
      const url =`api/customersetting/${payload.applicationId}/customer/${payload.customerId}`;
      axios.put(url, payload.data)
        .then(response => {         
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    },
    removeCustomer({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url =`api/customersetting/${payload.applicationId}/customer/${payload.customerId}`;
        axios.delete(url)
          .then(response => {           
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },






    // fetchFormData({ commit },payload) {      
    //   return new Promise((resolve, reject) => {        
    //     // const url = helper.StringFormat(helper.priceRuleAPIs.formDataAPI,payload);
    //     // axios.get(url)
    //     //   .then(response => {
    //     //     commit('SET_FORM_DATA', response.data);
    //     //     resolve(response);
    //     //   }).catch(error => {
    //     //     reject(error);
    //     //   });
    //   })
    // },
    // fetchCustomers({ commit }, payload) {
    //   // disabled to use dummy data
    //   return new Promise((resolve, reject) => {
    //     commit('SET_CUSTOMER_LIST', helper.customerLists);
    //     resolve();
    //     return;
    //     // const url = helper.StringFormat(helper.priceRuleAPIs.listAPI, payload);
    //     // axios.get(url)
    //     //   .then(response => {
    //     //     commit('SET_PRICE_RULE_LIST', response.data);
    //     //     resolve(response);
    //     //   }).catch(error => {
    //     //     reject(error);
    //     //   });
    //   })
    //   /**
    //    * the response data  structure 
    //    * [{
    //         "ruleId": 0,
    //         "ruleAppId": 0,
    //         "ruleName": null,
    //         "rulePriority": 0,
    //         "ruleIsActive": false,
    //         "ruleCreated": "0001-01-01T00:00:00"
    //       }]
    //    */
    // },
    // addCustomer({ commit }, payload) {
    //   // disabled to use dummy data
    //   return new Promise((resolve, reject) => {
    //     const url = helper.StringFormat(helper.priceRuleAPIs.addAPI, payload.applicationId);
    //     axios.post(url, { ruleData: JSON.stringify(payload.data) })
    //       .then(response => {
    //         /**
    //          * Eventually, fetchPriceRules will be executed upon successfull add request
    //          * So, commit here may not be needed
    //          * uncomment the following lines if needed
    //          */
    //         // commit('ADD_PRICE_RULE', {
    //         //   ruleId: response.data.ruleId,
    //         //   ruleAppId: payload.data.applicationId,
    //         //   ruleName: payload.data.name,
    //         //   rulePriority: payload.data.priority,
    //         //   ruleIsActive: payload.data.isActive,
    //         //   ruleCreated: response.data.ruleCreated,
    //         // });
    //         resolve(response);
    //       }).catch(error => {
    //         reject(error);
    //       });
    //   })
    // },
    // updateCustomer({ commit }, payload) {
    //   // return new Promise((resolve, reject) => {
    //   const url = helper.StringFormat(helper.priceRuleAPIs.updateAPI, payload.applicationId, payload.priceRuleId);
    //   axios.put(url, { ruleData: JSON.stringify(payload.data) })
    //     .then(response => {
    //       /**
    //          * Eventually, fetchPriceRules will be executed upon successfull update request
    //          * So, commit here may not be needed
    //          * uncomment the following lines if needed
    //          */
    //       // commit('UPDATE_PRICE_RULE', {
    //       //   ruleId: response.data.ruleId,
    //       //   ruleAppId: payload.data.applicationId,
    //       //   ruleName: payload.data.name,
    //       //   rulePriority: payload.data.priority,
    //       //   ruleIsActive: payload.data.isActive,
    //       //   ruleCreated: response.data.ruleCreated,
    //       // });
    //       resolve(response);
    //     }).catch(error => {
    //       reject(error);
    //     });

    // },
    // removeCustomer({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     const url = helper.StringFormat(helper.priceRuleAPIs.deleteAPI, payload.applicationId, payload.priceRuleId);
    //     axios.delete(url)
    //       .then(response => {
    //         /**
    //          * Eventually, fetchPriceRules will be executed upon successfull delete request
    //          * So, commit here may not be needed
    //          * uncomment the following lines if needed
    //          */
    //         //commit('REMOVE_PRICE_RULE', payload.priceRuleId)
    //         resolve(response);
    //       }).catch(error => {
    //         reject(error);
    //       });
    //   })
    // },
  }
}

